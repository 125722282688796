import React from 'react';
import styled from 'styled-components';
import { between } from 'styled-breakpoints';
import { Button, Text, Link } from 'src/components/Common';
import { URL } from 'src/url';

const Root = styled.div`
  display: flex;
  padding: 26px 0;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid #d5d6d6;

  ${between('mini', 'mobile')} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;

const Icon = styled.div`
  width: 20%;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  flex: 1;

  ${between('mini', 'mobile')} {
    justify-content: center;
    align-items: center;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 25px;
`;

const Detail = styled(Text)`
  position: relative;

  &:not(:last-child) {
    &::after {
      position: absolute;
      left: calc(100% + 11px);
      color: #000;
      content: '|';
    }
  }
`;

const PositionsListItem = ({ position }) => {
  return (
    <Root>
      {/* <Icon>Icon</Icon> */}
      <Details>
        <Text variant="h5" isBold>
          {position.name}
        </Text>
        <Row>
          <Detail>{position.department}</Detail>
          <Detail>{position.experienceLevel}</Detail>
          <Detail>{position.employmentType}</Detail>
        </Row>
      </Details>
      <Link to={`${URL.CAREERS}${position.id}`}>
        <Button
          variant="contained"
          text="Details"
          color="secondary"
          sx={{ fontSize: '20px' }}
          size="large"
        />
      </Link>
    </Root>
  );
};

export default PositionsListItem;
