import * as React from 'react';
import { graphql } from 'gatsby';
import Careers from '../../components/Careers';

const CareersPage = (props) => {
  const positions = props.data.allPositionsList.edges;
  return <Careers positions={positions} />;
};

export default CareersPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPositionsList {
      edges {
        node {
          id
          name
          experienceLevel
          employmentType
          department
          url
          details {
            name
            value
          }
        }
      }
    }
  }
`;
