import React from 'react';
import styled from 'styled-components';
import { SEO } from '../Common';
import { only, down } from 'styled-breakpoints';
import { Text, CustomizedAccordion } from '../../components/Common';
import PositionsListItem from './PositionsListItem';
import { MiddleLayout } from '../../components/Common';
import { COLORS } from 'src/style/colors';
import { jobsData } from './CareersDate';
import useMediaQuery from 'src/style/useMediaQuery';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { useLocation } from '@reach/router';

const Root = styled.main`
  ${only('mini')} {
    padding-bottom: 30px;
  } ;
`;

const Header = styled.div`
  background: var(--linear-secondary);
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  ${down('mini')} {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 15px;
  } ;
`;

const HeaderSubTitle = styled.div`
  max-width: 80%;
  text-align: center;
  ${down('mini')} {
    width: 100%;
    text-align: start;
  } ;
`;

const JobContentTitle = styled.div`
  margin-bottom: 30px;
`;

const JobContentList = styled.ul`
  margin-bottom: 200px;
  direction: ltr;
  ${down('mini')} {
    margin-bottom: 100px;
  } ;
`;

const JobContentItem = styled.li``;

const Careers = ({ positions }) => {
  const { t } = useTranslation();
  const { href } = useLocation();

  const breadcrumbsElementsSchema = [
    {
      '@type': 'ListItem',
      position: 2,
      name: t('Career'),
      item: href,
    },
  ];

  const { isLaptopOrAbove } = useMediaQuery();

  const createSubtitle = (textStr) => (
    <Text
      variant={isLaptopOrAbove ? 'h6' : 'p'}
      text={textStr}
      color={COLORS.WHITE}
    />
  );

  return (
    <Root>
      <SEO
        title="SEO title career"
        description="SEO description career"
        breadcrumbsElementsSchema={breadcrumbsElementsSchema}
      />
      <Header>
        <Text
          variant="h1"
          isBold
          text="Join the Appsforce"
          color={COLORS.WHITE}
        />
        <HeaderSubTitle>
          {createSubtitle('join us 1')}
          {createSubtitle('join us 2')}
        </HeaderSubTitle>
      </Header>
      <MiddleLayout>
        <JobContentTitle>
          <Text
            variant="h6"
            isBold
            text="apply today"
            color={COLORS.SECONDARY}
          />
          <Text variant="h3" isBold text="job openings" color={COLORS.BLACK} />
        </JobContentTitle>
        <JobContentList>
          {positions.map?.(({ node }, index) => (
            <JobContentItem key={node.id}>
              <CustomizedAccordion
                jobTitle={node.name}
                jobDetails={node.details}
                jobIcon={'icon'}
                jobUrl={node.url}
                accordionIndex={index}
              />
            </JobContentItem>
          ))}
        </JobContentList>
      </MiddleLayout>
    </Root>
  );
};

export default Careers;
